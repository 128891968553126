import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import BannerLanding from '../components/BannerLanding';
import pic11 from '../assets/images/pic11.jpg';
export default function Template(_ref) {
  var data = _ref.data;
  var markdownRemark = data.markdownRemark; // data.markdownRemark holds our post data

  var frontmatter = markdownRemark.frontmatter,
      html = markdownRemark.html;
  return React.createElement(Layout, null, React.createElement(Helmet, null, React.createElement("title", null, "Hospitality Tribe"), React.createElement("meta", {
    name: "description",
    content: ""
  })), React.createElement("div", {
    id: "main",
    className: "alt"
  }, React.createElement("section", {
    id: "one"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h1", null, frontmatter.title)), React.createElement("span", {
    className: "image main"
  }, React.createElement("img", {
    src: pic11,
    alt: ""
  })), React.createElement("div", {
    className: "blog-post-content",
    dangerouslySetInnerHTML: {
      __html: html
    }
  })))));
}
export var pageQuery = "1579142440";